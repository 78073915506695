import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { LayoutService } from 'src/app/services/layout-fix.service';
import { ProfileUpdateService } from 'src/app/services/ProfileUpdateService';
import { SupabaseService } from 'src/app/services/supabase.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  isMobile: boolean = window.innerWidth < 768;
  isMenuOpen: boolean = false;
  showLogoutDropdown: boolean = false;
  loading: boolean = false;
  name: string = '';
  selectedFileUrl: string | ArrayBuffer | null = null;
  supabaseService: any;

  constructor(private router: Router, public layoutService: LayoutService, private supabase: SupabaseService,
    private userService: UserService, private alertService: AlertService, private profileUpdateService: ProfileUpdateService
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveLink(event.urlAfterRedirects);

        if (event.urlAfterRedirects !== '/login' && event.urlAfterRedirects !== '/register') {
          this.loadUserProfile();
        }
      }
    });

    // Escute as atualizações do perfil
    this.profileUpdateService.profileUpdated$.subscribe((updated) => {
      if (updated) {
        this.loadUserProfile();
      }
    });

    this.updateLayout(window.innerWidth);
    document.addEventListener('click', this.handleOutsideClick.bind(this), true);
  }



  handleOutsideClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('#logout-dropdown') && this.showLogoutDropdown) {
      this.toggleLogoutDropdown();
    }
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.handleOutsideClick.bind(this), true);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateLayout(event.target.innerWidth);
  }

  updateLayout(width: number) {
    this.isMobile = width < 768;
  }

  async loadUserProfile() {
    // Tente carregar o perfil do localStorage
    const savedProfile = localStorage.getItem('userProfile');

    if (savedProfile) {
      const profile = JSON.parse(savedProfile);
      this.name = profile.name;
      this.selectedFileUrl = profile.img;
    } else {
      this.loading = true;
      const name = await this.userService.getName();
      const img = await this.userService.getImg();
      console.log(name, img)
      if (name) {
        this.name = name;
        this.selectedFileUrl = img;
        // Salve no localStorage para uso futuro
        localStorage.setItem('userProfile', JSON.stringify({ name, img }));

      } else {
        // this.alertService.showError('Erro', 'Falha ao carregar o perfil do usuário');
      }
      this.loading = false;
    }
  }

  updateActiveLink(url: string) {
    const links = document.querySelectorAll('.side-nav a');
    links.forEach(link => {
      const href = link.getAttribute('routerLink');
      if (url.includes(href || '')) {
        link.classList.add('active');
      } else {
        link.classList.remove('active');
      }
    });
  }

  onNavClick(route: string) {
    this.router.navigate([route]);
  }

  toggleLogoutDropdown() {
    this.showLogoutDropdown = !this.showLogoutDropdown;
  }

  async signOut() {
    try {
      this.loading = true;
      const { error } = await this.supabase.signOut();
      localStorage.clear()
      if (!error) {
        this.router.navigate(['/login']);
      } else {
        this.alertService.showError('Erro', 'Falha ao fazer logout');
      }
    } catch (err) {
      this.alertService.showError('Erro', 'Algo deu errado durante o logout');
    } finally {
      this.loading = false;
    }
  }



  closeDropdown() {
    if (this.showLogoutDropdown) {
      this.toggleLogoutDropdown();
    }
  }

  closeSidenav() {
    if (this.isMenuOpen) {
      this.toggleSidenav();
    }
  }

  toggleSidenav() {
    this.layoutService.toggleSidenav();
    this.isMenuOpen = !this.isMenuOpen;
  }
}
